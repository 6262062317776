export default {
  init(targets) {
    if ($(targets).length > 0) {
      $(targets).each((i, target) => {
        this.onScroll(target);
        $(window).on('scroll', () => this.onScroll(target));
      });
    }
  },
  onScroll(target) {
    if (!$(target).hasClass('found')) {
      const targetTop = $(target).offset().top;
      const mark = window.pageYOffset + (window.innerHeight/4*3);

      if (mark > targetTop) {
        $(target).addClass('found');
        this.rollUp($(target), $(target).closest('.roll-trans'));
      }
    }
  },
  rollUp(counter, trans) {
    const countNum = counter.data('roll');

    if (typeof countNum === 'string') {
      counter.text(countNum);
      trans.addClass('fadeIn');
    } else {
      $({ countNum: counter.text() }).animate({
        countNum: countNum,
        transition: 100
      }, {
        duration: 1000,
        easing: 'linear',
        step: function() {
          counter.text(Math.floor(this.countNum));
          trans.css({
            opacity: this.transition/100,
            transform: `translateY(${20 - ((this.transition/100)*20)}px)`
          });
        },
        complete: function() {
          counter.text(countNum);
          trans.css({
            opacity: 1,
            transform: `translateY(0)`
          });
        }
      });
    }


  }
}
