$.fn.extend({
  animateCssShow: function(animationName, callback) {
    callback = typeof callback !== 'undefined' ? callback : 42;
    this.show().addClass('animated ' + animationName).one(animEv, function() {
      $(this).removeClass('animated ' + animationName);
      if (callback != 42) {
        callback();
      }
    });
    return this;
  },
  animateCssFlexShow: function(animationName, callback) {
    callback = typeof callback !== 'undefined' ? callback : 42;
    this.css('display', 'flex').addClass('animated ' + animationName).one(animEv, function() {
      $(this).removeClass('animated ' + animationName);
      if (callback != 42) {
        callback();
      }
    });
    return this;
  },
  animateCssHide: function(animationName, callback) {
    callback = typeof callback !== 'undefined' ? callback : 42;
    this.addClass('animated ' + animationName).one(animEv, function() {
      $(this).removeClass('animated ' + animationName).hide();
      if (callback != 42) {
        callback();
      }
    });
    return this;
  },
  animateCss: function(animationName, callback) {
    callback = typeof callback !== 'undefined' ? callback : 42;
    this.addClass('animated ' + animationName).one(animEv, function() {
      $(this).removeClass('animated ' + animationName);
      if (callback != 42) {
        callback();
      }
    });
    return this;
  }
});

function whichTransitionEvent() {
  var t,
    el = document.createElement('fakeelement'),
    transitions = {
      'transition': 'transitionend',
      'OTransition': 'oTransitionEnd',
      'MozTransition': 'transitionend',
      'WebkitTransition': 'webkitTransitionEnd'
    };

  for (t in transitions) {
    if (el.style[t] !== undefined) {
      return transitions[t];
    }
  }
}

function whichAnimationEvent() {
  var t,
    el = document.createElement("fakeelement"),
    animations = {
      "animation": "animationend",
      "OAnimation": "oAnimationEnd",
      "MozAnimation": "animationend",
      "WebkitAnimation": "webkitAnimationEnd"
    };

  for (t in animations) {
    if (el.style[t] !== undefined) {
      return animations[t];
    }
  }
}

export const tranEv = whichTransitionEvent();
export const animEv = whichAnimationEvent();
export const isMS = navigator.userAgent.indexOf('MSIE') != -1 || !!navigator.userAgent.match(/Trident.*rv\:11\./) || navigator.userAgent.indexOf('Edge') != -1;
export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|CriOS/i.test(navigator.userAgent);
export const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export const isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1;

export function scrollTo(where, timing = 700) {
  const top = where - parseFloat($('.header').css('height')) + 20;
  $((iOS) ? "body" : "body, html").stop().animate({ scrollTop: top }, timing, function() {
    $(window).scrollTop(top);
  });
}

