import { tranEv } from "./common";

export default {
  init() {
    $("[data-text]").on("change keyup keydown keypress", (e) => {
      if ($.trim(e.target.value).length > 0) {
        $(e.target).closest(".form_input").addClass('full');
      } else {
        $(e.target).closest(".form_input").removeClass('full');
      }
    });

    $(".form_input input").on("change keyup keydown keypress", (e) => {
      if ($(e.target).closest('.form_input').hasClass('error')) {
        $(e.target).closest(".form_input").removeClass('error').find('.error_msg').one(tranEv, function(){
          $(this).remove();
        });
      }
    });

    $("[data-text]").on('focusin', (e) => {
      $(e.target).closest(".form_input").addClass('focus');
    });

    $("[data-text]").on('focusout', (e) => {
      $(e.target).closest(".form_input").removeClass('focus');
    });

    $("[data-file]").on('change', (e) => {
      $(e.target).siblings('[data-text]').val(this.fileName($(e.target).val())).trigger('change');
    });

    $("[data-async]").on('submit', (e) => {
      e.preventDefault();
      const formData = new FormData(e.target);
      const $self = this;

      $.ajax({
        url: for_ajax.url,
        data: formData,
        processData: false,
        contentType: false,
        type: 'POST',
        success(resp) {
          $self.handleResponse(JSON.parse(resp), e.target);
        }
      });
    });

    $('body').on('click', '.form_message .back:not(.form_close)', (e) => {
      $(e.currentTarget).closest('.form_message').fadeOut(300, function() {
        $(this).remove();
      });
    });

    $('body').on('click', '.form_message .back.form_close', (e) => {
      $(e.currentTarget).closest('.modal').fadeOut(300, function(){
        $('body').removeClass('fixed');
        $(e.currentTarget).closest('.form_message').remove();
      });
    });
  },
  fileName(fullPath) {
    if (fullPath) {
      var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
      var filename = fullPath.substring(startIndex);
      if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1);
      }
      return filename;
    } else {
      return '';
    }
  },
  handleResponse(resp, form) {
    if (resp.status === "badfields") {
      this.badFields(resp.fields, form);
    } else if (resp.status === "success") {
      if (resp.redirect) {
        this.redirect(resp.redirect);
      }
      else if (resp.type == 'newsletter') {
        this.handleNewsletterResponse();
      } else {
        this.outputMessage(resp, form);
      }
    } else if (resp.status === "error") {
      this.outputMessage(resp, form);
    }
  },
  badFields(fields, form) {
    fields.map((field, i) => {
      $(form).find(`input[name="${field.name}"]`).closest('.form_input').append(`<div class="error_msg">${field.error}</div>`);
      setTimeout(() => {
        $(form).find(`input[name="${field.name}"]`).closest('.form_input').addClass('error');
      }, 1 + (i * 200));
    });
  },
  redirect(url) {
    window.location.href = url;
  },
  handleNewsletterResponse() {
    $('[data-newsletter-text]').addClass('disabled');
    $('[data-newsletter-success]').removeClass('disabled');
  },
  outputMessage(resp, form) {
    const output = resp.output;
    let button =  '';

    if (output.button) {
      if (output.button.link === 'back') {
        button = `<div class="btn back">${output.button.text}</div>`;
      } else if (output.button.link === 'close') {
        button = `<div class="btn back form_close">${output.button.text}</div>`;
      } else {
        button = `<a href="${output.button.link}">${output.button.text}</a>`;
      }
    }

    const message = `
      <div class="form_message form_message-${resp.status}">
        <div class="icon"></div>
        <h4>${output.heading}</h4>
        <p>${output.body}</p>
        ${button}
      </div>
    `;

    let pdfUrl = output.pdf_url;
    if(pdfUrl) {
      setTimeout(function(){
        window.location.href = pdfUrl;
      }, 2500)
    }

    form.reset();
    $(form).append(message).find('.form_message').css({ display: "flex" }).hide().fadeIn(300);
    $(form).find('.form__titleIcon').hide();
  }
};