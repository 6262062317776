import mega from "./scripts/mega";
import slick from "./scripts/slick";
import dots from "./scripts/dots";
import rollup from "./scripts/rollup";
import switchable from "./scripts/switchable";
import faq from "./scripts/faq";
import plans from "./scripts/plans_mobile";
import overflow from "./scripts/overflow";
import form from "./scripts/form";
import history from "./scripts/history";
import maps from "./scripts/maps";
import tabs from "./scripts/tabs";
import modal from "./scripts/modal";
import heroSlider from "./scripts/heroSlider";
import cookie from "./scripts/cookie";
import mobileMenu from "./scripts/mobileMenu";
import { scrollTo, isMS, tranEv } from "./scripts/common";
import video from "./scripts/video";
import mobileSub from "./scripts/mobileSub";
import blogScroll from "./scripts/blog";
import gallery from './scripts/gallery';
import timeline from "./scripts/timeline";

$.extend($.easing, {
  easeOutBounce: function (x, t, b, c, d) {
    if ((t/=d) < (1/2.75)) {
        return c*(7.5625*t*t) + b;
    } else if (t < (2/2.75)) {
        return c*(7.5625*(t-=(1.5/2.75))*t + .75) + b;
    } else if (t < (2.5/2.75)) {
        return c*(7.5625*(t-=(2.25/2.75))*t + .9375) + b;
    } else {
        return c*(7.5625*(t-=(2.625/2.75))*t + .984375) + b;
    }
  }
});

const onLoad = (e) => {
  inits();
  onResize(e);

  // if ($('.hero').length > 0) {
  //   $('.header').addClass('trans');
  // }

  // if ($('.pageheading').length > 0) {
  //   $('.header').addClass('trans blue');
  // }

  if ($('.hero').length < 1) {
    $('.header').addClass('white');
  }

  if ($('.pageheading').length > 0) {
    $('.header').addClass('blue');
  }

  if ($('section.gallery').length > 0 && gallery) gallery();

  if ($('[data-autoplay]').length > 0) {
    sliderAutoplay();
  }

  if($('.blog_list').length > 0) {
    blogScroll.init();
  }

  if($('.tech_icons').length > 0) {
    let count = $('.tech_icons').find('.tech_icon').length;
    if(count < 5) {
      $('.tech_icons').css('justify-content', 'left');
    }
  }
};

const onResize = (e) => {
  if (isMS && $('.obFit').length > 0) {
    const videos = document.getElementsByClassName('obFit');

    Object.keys(videos).forEach((i) => {
      const el = videos[i];
      const videoRatio = el.videoWidth / el.videoHeight
      const viewportRatio = window.innerWidth / window.innerHeight

      el.style.width  = viewportRatio >= videoRatio ? '100%' : '';
      el.style.height = viewportRatio <= videoRatio ? '100%' : '';

      el.classList.add('polyfill');
    });
  }

  if ($(window).width() < 992) {
    if($('.blog_block__img').length > 0) {
      $($('.blog_block__img')).each(function(){
        var transfer = $(this);
        var target = $(this).siblings('.blog_block__content').find('.blog_block__topic');
        transfer.insertAfter(target);
      });
    }
    
  }

  onScroll(e);
};

const onScroll = (e) => {
  const hook = window.pageYOffset + (window.innerHeight / 4 * 3);

  if (window.pageYOffset > 100) {
    $('.header').addClass('scrolled');
  } else {
    $('.header').removeClass('scrolled');
  }

  if ($('.loader:not(.found)').length > 0) {
    $('.loader').addClass('found').fadeOut(600, function(){
      $(this).remove();
      onScroll(e);
    });
  }

  if ($('.loader').length < 1) {
    if ($('.target:not(.trigger)').length > 0) {
      $('.target:not(.trigger)').each((idx, target) => {
        if (hook > $(target).offset().top) {
          $(target).addClass('trigger');
        } else if ($(target).closest('.footer').length > 0 && window.pageYOffset + window.innerHeight > $(target).offset().top) {
          $(target).addClass('trigger');
        }

        if ($(target).hasClass('trigger') && $(target).closest('.history').length > 0 && !$(target).hasClass('bouncing')) {
          $(target).addClass('bouncing');

          $(target).find('.history_item').last().one(tranEv, () => {
            $({ bouncer: window.innerWidth/2 }).stop(true, true).animate({ bouncer: 0 }, {
              duration: 1000,
              easing: 'easeOutBounce',
              step: function() {
                $(target).find('.history_list').get(0).scrollLeft = this.bouncer;
              },
              complete: function() {
                $(target).addClass('bounced').find('.history_list').get(0).scrollLeft = 0;
              }
            });
          });
        }
      });
    }

    if ($('.bg').length > 0 && $('[data-bg]').length > 0) {
      $('[data-bg]').each((idx, section) => {
        const top = $(section).offset().top;
        const bottom = $(section).offset().top + parseFloat($(section).css('height'));

        if (hook >= top && hook < bottom) {
          $('.bg').attr('which', $(section).data('bg'));
        } else if ($(section).is('.footer') && window.pageYOffset + window.innerHeight > $(section).offset().top) {
          $('.bg').attr('which', $(section).data('bg'));
        }
      });
    }
  }
};

const inits = () => {
  // overflow.init('.history_list');
  overflow.init('.order_table-wrap');
  overflow.init('.addons_toggles-wrap');
  overflow.init('table:not(.order_table)');
  mega.init('.mega');
  heroSlider.init('.heroSlider');
  slick.init('.testi');
  slick.init('.solutions');
  slick.init('.related');
  rollup.init('span[data-roll]');
  switchable.init('.addons');
  faq.init('.faq');
  plans.init();
  history.init('.history_list', '.history_item', '.history_wrap');
  form.init();
  maps.init('#map');
  tabs.init('.tabs');
  cookie.init('.cookie .btn');
  mobileMenu.init('.header_mobToggler');
  modal.init();
  video.init('.hero video');
  mobileSub.init('.has-sub');
  timeline.init();

  $('body').on('mouseenter', '.hoverdots_dot', (e) => {
    dots.hover(e.currentTarget);
  });

  $('body').on('mouseleave', '.hoverdots', (e) => {
    dots.clear(e.currentTarget);
  });

  $('body').on('click', '.scrollDown .inner', () => {
    scrollTo(window.innerHeight);
  });

  $('body').on('click', '.footer_scrollTop', () => {
    scrollTo(0);
  });

  $('body').on('click', '.modal#search .modal_suggests span', (e) => {
    $('.modal#search input#search_s').val($(e.currentTarget).text()).trigger('change').closest('.form_input').one(tranEv, () => {
      $('.modal#search input#search_s').closest('form').submit();
    });
  });
}

var pagCur = 1;
var autoDur = $('.heroSlider').data('autoplay-dur');
console.log(autoDur);
function sliderAutoplay() {
  var pag = $('.heroSlider').find('.heroSlider_dot');
  var pagL = pag.length;
  setTimeout(function () {
      $(pag[pagCur]).trigger('click');
      if(pagCur === pagL - 1) {
        pagCur = 0;
      } else {
      pagCur += 1;
      }
      sliderAutoplay();
  }, autoDur);
};

$("[data-blog-form-trigger]").on("click", function(e){
  e.preventDefault();
  let cForm = $(this).parent().parent();
  let formAction = cForm.attr("action");
  let inputs = cForm.serializeArray();
  if( inputs.length > 0 )
  {
      let filterTags = '';
      for (var items of inputs) 
              filterTags += items.name + ';';

      formAction += '/' + filterTags + '/';
      cForm.attr("action", formAction).trigger("submit");
  }
})


$(window).on('scroll', onScroll);
$(window).on('resize', onResize);
$(window).on('load', onLoad);