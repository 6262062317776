export default {
  init(megas) {
    $(megas).each((i, mega) => {
      this.resize(mega);

      $(mega)
        .find(".mega_icon svg")
        .each((i, svg) => {
          const width = parseFloat($(svg).attr("width"));
          const height = parseFloat($(svg).attr("height"));

          if (width && height) {
            $(svg)
              .removeAttr("width")
              .removeAttr("height")
              .attr("viewBox", `0 0 ${width} ${height}`);
          }
        });

      $(mega)
        .siblings(".menu_link")
        .on("click", (e) => {
          if (window.innerWidth < 992) {
            $(e.currentTarget).closest(".menu_item").addClass("open");
            $(e.currentTarget)
              .closest(".menu_item")
              .siblings(".open")
              .removeClass("open");
          }
        });

      $(mega)
        .find(".mega_toggler")
        .first()
        .addClass("active")
        .siblings(".active")
        .removeClass("active");
      $(mega)
        .find(".mega_placeholder")
        .css({
          height: $(mega).find(".mega_toggler").first().attr("megasize")
            ? parseFloat($(mega).find(".mega_toggler").first().attr("megasize"))
            : 0,
        });

      $(window).on("resize", () => {
        this.resize(mega);
      });

      $(mega).on("mouseenter mouseleave load", () => {
        if (window.innerWidth > 991) {
          $(mega)
            .find(".mega_toggler")
            .first()
            .addClass("active")
            .siblings(".active")
            .removeClass("active");
          $(mega)
            .find(".mega_placeholder")
            .css({
              height: $(mega).find(".mega_toggler").first().attr("megasize")
                ? parseFloat(
                    $(mega).find(".mega_toggler").first().attr("megasize")
                  )
                : 0,
            });
        }
      });

      $(mega)
        .find(".mega_toggler")
        .on("mouseenter click", function () {
          $(this).addClass("active").siblings(".active").removeClass("active");
          $(mega)
            .find(".mega_placeholder")
            .css({
              height: $(this).attr("megasize")
                ? parseFloat($(this).attr("megasize"))
                : 0,
            });
        });

      $(mega).on("click", (e) => {
        if (
          !$(e.target).is(".mega_switch") &&
          $(e.target).closest(".mega_switch").length < 1
        ) {
          $(mega).find(".mega_switch").removeClass("open");
        }
      });

      $(mega)
        .find(".mega_switch-toggle")
        .on("click", (e) => {
          $(e.currentTarget).parent().toggleClass("open");
        });

      $(mega)
        .find(".mega_switch-list ul li")
        .on("click", (e) => {
          $(e.currentTarget).closest(".mega_switch").removeClass("open");
          $(mega).find(".mega_switch-toggle").text($(e.currentTarget).text());
          $(mega)
            .find(".mega_toggler")
            .eq($(e.currentTarget).index())
            .addClass("active")
            .siblings(".active")
            .removeClass("active");
        });

      $(mega)
        .find(".back")
        .on("click", () => {
          $(mega).closest(".menu_item").removeClass("open");
          $(mega)
            .find(".mega_toggler")
            .first()
            .addClass("active")
            .siblings(".active")
            .removeClass("active");
          $(mega)
            .find(".mega_switch-toggle")
            .text($(mega).find(".mega_switch-list ul li").first().text());
        });
    });

    $(".mega_submenu").each(function () {
      var subs = $(this).find(".mega_subitem").length;
      if (subs > 9) {
        $(this).addClass("overflow");
      }
    });
  },
  resize(mega) {
    $(mega)
      .find(".mega_togglers")
      .attr(
        "megasize",
        parseFloat($(mega).find(".mega_togglers").css("height"))
      );

    $(mega)
      .find(".mega_toggler")
      .each((i, toggler) => {
        const menuHeight =
          parseFloat($(toggler).find(".mega_submenu").css("height")) - 36;
        const togglesHeight =
          parseFloat($(toggler).closest(".mega_togglers").css("height")) - 36;
        $(toggler).attr(
          "megasize",
          menuHeight > togglesHeight ? menuHeight : togglesHeight
        );
      });
  },
};
