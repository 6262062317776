export default {
  init(className) {
    const items = $(`${className}_item`);

    $('body').on('click', `${className}_toggler`, (e) => {
      $(e.currentTarget).closest(`${className}_item`).toggleClass('active');
    });

    this.resize(className);
    $(window).on('resize', () => {
      this.resize(className);
    })
  },
  resize(className) {
    $(`${className}_item`).each((i, item) => {
      $(item).find(`${className}_text`).css({
        height: parseFloat($(item).find(`${className}_text-wrap`).css('height'))
      });
    });
  }
}