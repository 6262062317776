export default {
  init(video) {
    if ($(video).length > 0) {
      $(video).each((i, vid) => {
        this.setupVideo(vid);
      });
    }
  },
  setupVideo(vid) {
    if (vid.readyState >= 2) {
      this.resize(vid);
    } else {
      vid.addEventListener('loadedmetadata', (e) => {
        this.resize(vid);
      });
    }

    $(window).on('resize', () => this.resize(vid));
  },
  resize(vid) {
    const contWidth = parseInt($(vid).parent().css('width'));
    const contHeight = parseInt($(vid).parent().css('height'));

    const video = $(vid).get(0);

    const vidWidth = video.videoWidth;
    const vidHeight = video.videoHeight;

    const vidHeightToWidth = vidHeight / vidWidth;
    const vidWidthToHeight = vidWidth / vidHeight;

    if (contHeight * vidWidthToHeight > contWidth) {
      $(vid).css({
        height: contHeight,
        width: contHeight*vidWidthToHeight
      });
    } else {
      $(vid).css({
        height: contWidth * vidHeightToWidth,
        width: contWidth
      });
    }
  }
}