export default {
  init(list, items, wrap) {
    let highest = 0;

    $(items).each((idx, item) => {
      if (parseFloat($(item).css('height')) > highest) {
        highest = parseFloat($(item).css('height'));
      }
    });

    $(items).css({
      height: highest
    });

    $(list).css({
      height: 2 * (highest) + 34,
    }).addClass('show');

    $(wrap).css({
      height: 2 * (highest) + 34
    });
  }
};
