export default {
  init() {
    let btnPrev = $(".time-nav__next");
    let btnNext = $(".time-nav__prev");
    let container = $(".history_wrap");
    let containerWidth = container.width();
    let svg = $(".history_list");

    $(document).on("scroll resize load", function () {
      if ($(".history_shads").length > 0) {
        horizontalPosition();
      }
    });

    btnPrev.on("click", function () {
      disableButtons();
      var far = containerWidth;
      var pos = $(".history_shads").scrollLeft();
      $(".history_shads").animate(
        { scrollLeft: pos + far },
        {
          duration: 1000,
          complete: function () {
            horizontalPosition();
            enableButtons();
          },
        }
      );
    });

    btnNext.on("click", function () {
      disableButtons();
      var far = containerWidth;
      var pos = $(".history_shads").scrollLeft();
      $(".history_shads").animate(
        { scrollLeft: pos - far },
        {
          duration: 1000,
          complete: function () {
            horizontalPosition();
            enableButtons();
          },
        }
      );
    });

    function disableButtons() {
      btnPrev.addClass("disabled");
      btnNext.addClass("disabled");
    }

    function enableButtons() {
      btnPrev.removeClass("disabled");
      btnNext.removeClass("disabled");
    }

    function horizontalPosition() {
      var scrolLeft = $(window).scrollLeft();
      var windw = $(".history_shads").width(); //important
      var documet = $(".history_list").width();

      if ($(".history_shads").scrollLeft() + windw == documet) {
        $(".time-nav__prev").addClass("disabled");
      } else {
        $(".time-nav__prev").removeClass("disabled");
      }

      if (svg.offset().left == 0) {
        $(".time-nav__next").addClass("disabled");
      } else {
        $(".time-nav__next").removeClass("disabled");
      }
    }
  },
};
