export default {
  init() {
    $("body").on("click", ".modal_close", (e) => {
      this.closeModal($(e.target).closest(".modal").get(0));
    });

    $("body").on('click', "[data-modal]", (e) => {
      this.showFilters(`#${$(e.currentTarget).data('filtergroup')}`);
      this.openModal(`.modal#${$(e.currentTarget).data('modal')}`);

      
      const passData = $(e.currentTarget).data('passdata');
      if (passData) {
        $(`.modal#${$(e.currentTarget).data('modal')}`).find(`input[name="${passData.name}"]`).val(passData.value);
      }
    });
  },
  openModal(modal) {
    $('body').addClass('fixed');
    $(modal).fadeIn(300, () => {
      $(modal).find('input:not([type=submit])').first().focus();
    });
  },
  closeModal(modal) {
    $(modal).fadeOut(300, function(){
      $('body').removeClass('fixed');
      $('.filterGroup').hide();
    });
  },
  showFilters(filters) {
    $(filters).fadeIn(100);
  }
}
