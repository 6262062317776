
import { tranEv } from "./common";

export default {
  init(className) {
    this.buildSlider(className);

    // Left click
    // $('body').on('click', `${className}:not(.moving) ${className}_arrow-left`, (e) => {
    //   prevSlide($(e.currentTarget).closest(className), className);
    // });

    // Right click
    // $('body').on('click', `${className}:not(.moving) ${className}_arrow-right`, (e) => {
    //   nextSlide($(e.currentTarget).closest(className), className);
    // });

    $('body').on('click', `${className}:not(.moving) ${className}_dot`, (e) => {
      this.otherSlide($(e.currentTarget).closest(className), $(e.currentTarget).index(), className);
    });
  },
  buildSlider(className) {
    $(className).each((i, slider) => {
      const slides = $(slider).find(`${className}_slides`).clone();
      slides.find('.hero_bg').remove();
      slides.find(`${className}_slide`).removeClass(`${className.substring(1)}_slide hero_wrap`).addClass('fakeSlide');
      slides.removeClass(`${className.substring(1)}_slides`).addClass(`${className.substring(1)}-fakes`);
      $(slider).find(`${className}_slides`).after(slides);

      let dot = "";

      $(slider).find(`${className}_slide`).each((i, slide) => {
        if (i === 0) {
          $(slide).addClass('active');
          dot += `<li class="${className.substring(1)}_dot active"><button>${i+1}</button></li>`;
        } else {
          dot += `<li class="${className.substring(1)}_dot"><button>${i+1}</button></li>`;
        }
      });

      const dots = `
        <div class="${className.substring(1)}_dots">
          <ul>
            ${dot}
          </ul>
        </div>
      `;

      $(slider).find(`${className}_controls .container .wrap`).append(dots);

      this.playVideo($(slider).find(`${className}_slide`).first().addClass('active'));
    });
  },
  prevSlide(slider, className) {
    const active = slider.find(`${className}_slide.active`);
    const slides = slider.find(`${className}_slide`);

    if (active.prev().length > 0) {
      this.otherSlide(slider, active.prev().index(), className);
    } else {
      this.otherSlide(slider, slides.last().index(), className);
    }
  },
  nextSlide(slider) {
    const active = slider.find(`${className}_slide.active`);
    const slides = slider.find(`${className}_slide`);
    
    if (active.next().length > 0) {
      this.otherSlide(slider, active.next().index(), className);
    } else {
      this.otherSlide(slider, slides.first().index(), className);
    }
  },
  playVideo(slide) {
    const video = slide.find('video').get(0);
    if (video) {
      video.play();
    }
  },
  stopVideo(slide){
    const video = slide.find('video').get(0);
  
    if (video) {
      video.pause();
      video.currentTime = 0;
    }
  },
  otherSlide(slider, idx, className) {
    const active = slider.find(`${className}_slide.active`);
    const moving = slider.find(`${className}_slide.moving`);
    const slides = slider.find(`${className}_slide`);
    
    if (slides.length > 1 && moving.length < 1 && idx !== active.index()) {
      if (active.index() < idx) {
        active.addClass('right');
        slider.addClass('moving');
        slides.eq(idx).addClass('active moving');
        this.playVideo(slides.eq(idx));
        active.find('.hero_bg').one(tranEv, () => {
          slides.eq(idx).removeClass('done moving');
          slider.removeClass('moving');
          active.removeClass('active right').addClass('done');
          this.stopVideo(active);
          slides.eq(idx).prevAll().addClass('done');
        });
      } else {
        active.addClass('left');
        slider.addClass('moving');
        slides.eq(idx).addClass('active moving');
        this.playVideo(slides.eq(idx));
        active.find('.hero_bg').one(tranEv, () => {
          slides.eq(idx).removeClass('done moving');
          slider.removeClass('moving');
          active.removeClass('active left done');
          this.stopVideo(active);
          slides.first().nextAll().removeClass('done');
        });
      }
  
      slider.find(`${className}_dot`).eq(idx).addClass('active').siblings('.active').removeClass('active');
    }
  }
}
