import PerfectScrollbar from 'perfect-scrollbar';
import {isMobile} from "./common";

export default {
  init(offset) {
    if ($(offset).length > 0) {
      if (offset === 'table:not(.order_table)') {
        $(offset).wrap('<div class="table_wrap"></div>');
        offset = ".table_wrap";
      }
      $(offset).each((i, ofst) => {
        new PerfectScrollbar(ofst, {
          wheelPropagation: true,
          suppressScrollY: !isMobile,
          useBothWheelAxes: true,
        });

        $(ofst).attr('scrollleft', 0);

        ofst.addEventListener('ps-scroll-x', () => {
          $(ofst).attr('scrollleft', ofst.scrollLeft);
        });
      });

    }
  }
};