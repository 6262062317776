import Swiper from 'swiper';

const gallery = () => {
    const info_sliders = document.querySelectorAll('section.gallery .info_holder');
    const gallery_sliders = document.querySelectorAll('section.gallery .gallery_slider'); 
    const galleries = document.querySelectorAll('section.gallery')

    for (let i = 0, j = info_sliders.length; i < j; i++) {
        const info_element = info_sliders[i];
        const gallery_element = gallery_sliders[i];

        if (!info_element || !gallery_element) break;

        const info_swiper = new Swiper(info_element, {
            fadeEffect: { crossFade: true },
            effect: 'fade',
            slidesPerView: 1,
            speed: 500,
            allowTouchMove: false,
            loop: true
        });

        const gallery_swiper = new Swiper(gallery_element, {
            speed: 1500,
            allowTouchMove: true,
            loop: true,
            effect: 'slide',
            centeredSlides: true,
            slidesPerView: '1',
            on: {
                slideChange: function() {
                    const slideIndx = gallery_swiper.activeIndex;
                    info_swiper.slideTo(slideIndx);
                }
            },
            breakpoints: {
                // when window width is >= 0px
                0: {
                    spaceBetween: 0
                },
                // when window width is >= 993px
                993: {
                    spaceBetween: -15
                }
              }
        });
    
    }

    function goTo(node, dir) {
        const gallery = node.closest('section');
        const gallery_slider = gallery.querySelector('.gallery_slider').swiper;

        if (!gallery_slider) return;

        if (dir === 'prev') {
            gallery_slider.slidePrev();
        }
        if (dir === 'next') {
            gallery_slider.slideNext();
        }
    }

    galleries.forEach(section => {
        section.addEventListener('click', function(e) {
            const target = e.target;

            if (
                target.classList.contains('gallery_back') || 
                target.closest('.gallery_back')
            ) {
                goTo(this, 'prev');
            } else if (
                target.classList.contains('gallery_forward') || 
                target.closest('.gallery_forward')
            ) {
                goTo(this, 'next');
            }
        });
    });
    
}

export default gallery;