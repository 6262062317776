export default {
  init(className) {
    const form = `${className}_toggles form`;
    const texts = `${className}_texts`;

    if ($(texts).find('.active').length > 0 && $(form).find('input:checked').length > 0) {
      this.switch(form, texts);

      $(form).on('change', () => {
        this.switch(form, texts);
      });

      $(window).on('resize', () => {
        this.resize(texts);
      });
    }
  },
  switch(form, texts) {
    const values = {};

    $(form).serializeArray().map((field) => {
      values[field.name] = field.value;
    });

    $(`#${values.addon}`).addClass('active').siblings('.active').removeClass('active');
    this.resize(texts);
  },
  resize(texts) {
    const wrap = $(texts);

    wrap.css({
      height: $(texts).find('.active').css('height')
    })
  }
}