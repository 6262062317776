export default {
    init(btn) {
      $('body').on('click', btn, () => {
        if (!$(btn).hasClass('open')) {
          this.openMenu(btn);
        } else {
          this.closeMenu(btn);
        }
      });
  
      $(window).on('resize', () => {
        if (window.innerWidth > 991 && $('.header').hasClass('open')) {
          this.closeMenu();
        }
      });
    },
    openMenu(th) {
        console.log(th);
      $(th).addClass('open');
      $('body').addClass('fixed');
    },
    closeMenu(th) {
      $(th).removeClass('open');
      if (!$('.modal').is(":visible")) {
        $('body').removeClass('fixed');
      }
    //   $('.header').find('.has-mega.open').removeClass('open');
    //   $('.header').find('.mega_toggler.active').removeClass('active').each((i, toggl) => {
    //     $(toggl).parent().children().first().addClass('active');
    //     $(toggl).closest('.wrap').find('.mega_switch-toggle').text($(toggl).closest('.wrap').find('.mega_switch-list ul li').first().text());
    //   });
    }
  }