export default {
  init() {
    var acc = document.getElementsByClassName("order_item__toggler_btn");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function() {
        this.closest('.order_item__toggler').classList.toggle("active");
        var panel = this.closest('.order_item__toggler').previousElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    }

    var secTog = document.getElementsByClassName("theadToggler");
    var j;

    for (j = 0; j < acc.length; j++) {
      secTog[j].addEventListener("click", function() {
        var parTable = this.closest('.order_item');
        var target = $(parTable).find('.order_item__toggler_btn');
        target.click();
      });
    }
  }
}