export default {
  init(btn) {
    $('body').on('click', btn, () => {
      if (!$('.header').hasClass('open')) {
        this.openMenu();
      } else {
        this.closeMenu();
      }
    });

    $(window).on('resize', () => {
      if (window.innerWidth > 991 && $('.header').hasClass('open')) {
        this.closeMenu();
      }
    });
  },
  openMenu() {
    $('.header').addClass('open');
    $('body').addClass('fixed');
  },
  closeMenu() {
    $('.header').removeClass('open');
    if (!$('.modal').is(":visible")) {
      $('body').removeClass('fixed');
    }
    $('.header').find('.has-mega.open').removeClass('open');
    $('.header').find('.mega_toggler.active').removeClass('active').each((i, toggl) => {
      $(toggl).parent().children().first().addClass('active');
      $(toggl).closest('.wrap').find('.mega_switch-toggle').text($(toggl).closest('.wrap').find('.mega_switch-list ul li').first().text());
    });
  }
}