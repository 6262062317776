export default {
  hover(dot) {
    this.clear($(dot).closest('.hoverdots'));
    const [i, j] = $(dot).data('matrix') ? $(dot).data('matrix') : ['', ''];

    const matrix = [
      [i-1, j-1], [i, j-1], [i+1, j-1],
      [i-1,   j], 'hover' , [i+1, j  ],
      [i-1, j+1], [i, j+1], [i+1, j+1],
    ];

    const matrixFar = [
                  [i-1, j-2], [i, j-2], [i+1, j-2],
      [i-2, j-1],                                   [i+2, j-1],
      [i-2, j  ],                                   [i+2, j  ],
      [i-2, j+1],                                   [i+2, j+1],
                  [i-1, j+2], [i, j+2], [i+1, j+2],
    ];

    matrix.forEach((d) => {
      if (d !== 'hover') {
        $(dot).siblings(`[data-matrix="[${d[0]}, ${d[1]}]"]`).addClass('near');
      } else {
        $(dot).addClass('hover');
      } 
    });

    matrixFar.forEach((d) => {
      $(dot).siblings(`[data-matrix="[${d[0]}, ${d[1]}]"]`).addClass('far');
    });
  },
  clear(dots) {
    $(dots).find('.near, .hover, .far').removeClass('near hover far');
  }
}