import 'slick-carousel';

export default {
  setCallbacks(type) {
    if (type === ".heroSlider") {
      $(`${type}_slides`).on('init', function(e, slick){
        const video = $(slick.$slides[slick.currentSlide]).find('video').get(0);
        if (video) {
          video.play();
        }
      });

      $(`${type}_slides`).on('afterChange', (e, slick, currentSlide) => {
        const video = $(slick.$slides[currentSlide]).find('video').get(0);

        Object.keys(slick.$slides).forEach((i) => {
          const video = $(slick.$slides[i]).find('video').get(0);

          if (video) {
            video.pause();
            video.currentTime = 0;
          }
        });

        if (video) {
          video.play();
        }
      });
    }
  },
  getOptions(type) {
    this.setCallbacks(type);

    switch (type) {
      case ".heroSlider": {
        return {
          infinite: true,
          speed: 700,
          slidesToShow: 1,
          arrows: false,
          dots: true,
          appendDots: $(`${type}_controls .paging`)
        };
      }
      case ".testi": {
        return {
          infinite: true,
          speed: 700,
          slidesToShow: 1,
          arrows: true,
          fade: true,
          autoplay: true,
          autoplaySpeed: 6500,
          pauseOnHover: true
        };
      }
      case ".solutions": {
        return {
          infinite: true,
          speed: 500,
          arrows: true,
          infinite: false,
          slidesToShow: 3,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 599,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        };
      }
      case ".related": {
        return {
          infinite: true,
          speed: 500,
          arrows: true,
          infinite: false,
          slidesToShow: 4,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 599,
              settings: {
                slidesToShow: 2
              }
            }
          ]
        };
      }
    }
  },
  init(sliderSelect) {
    if ($(`${sliderSelect}_slide`).length > 0) {
      $(`${sliderSelect}_slides`).slick(this.getOptions(sliderSelect));
    }
  }
}
