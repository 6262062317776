import { animEv } from "./common";

export default {
  init(btnSelect) {
    const btn = $(btnSelect);
    const wrap = btn.closest('.cookie');

    if (wrap.length > 0) {
      if (this.checkCookie('acceptCookie')) {
        wrap.remove();
      } else {
        wrap.fadeIn(700, () => {
          btn.on('click', () => {
            this.setCookie('acceptCookie', true, 365);
            wrap.fadeOut(700, () => {
              wrap.remove();
            });
          });
        });
      }
    }
  },
  setCookie(cname, cvalue, exdays) {
    let expires = "";
    if (exdays !== "delete") {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      expires = "expires="+d.toUTCString();
    } else {
      expires = "01 Jan 1970 00:00:01 GMT";
    }

    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },
  getCookie(cname) {
    const name = cname + "=";
    const ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  checkCookie(cname) {
    return this.getCookie(cname) != "";
  },
  deleteCookie(cname) {
    this.setCookie(cname, "", "delete");
  }
}
