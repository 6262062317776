
export default {
  init(mapID) {
    if ($(mapID).length > 0) {
      google.maps.event.addDomListener(window, 'load', this.setupMap(mapID))
    }
  },
  setupMap(mapID) {
    const div = $(mapID);
    const locations = div.data('locations');
    const bounds  = new google.maps.LatLngBounds();
    const pin = {
      url: for_ajax.themeUrl + '/src/assets/img/pin.svg?v=3',
      scaledSize: new google.maps.Size(54, 65),
      origin: new google.maps.Point(0,0)
    };

    const mapOptions = {
      panControl: false,
      rotateControl: false,
      scaleControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      scrollwheel: false,
      zoomControl: true,
      styles: [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#dbdbdb"},{"visibility":"on"}]}]
    };
    const map = new google.maps.Map(div.get(0), mapOptions);
    const markers = [];

    locations.forEach((location, i) => {
      const loc = new google.maps.LatLng(location['lat'], location['lng']);

      bounds.extend(loc);

      markers.push(new google.maps.Marker({
        position: loc,
        map: map,
        animation: google.maps.Animation.DROP,
        icon: pin,
        url: location['link']
      }));

      google.maps.event.addListener(markers[i], 'click', function() {
        window.open(markers[i].url, '_blank');
      });
    });

    map.fitBounds(bounds);

    const listener = google.maps.event.addListener(map, "idle", function () {
      map.setZoom(5);
      google.maps.event.removeListener(listener);
    });
  }
}