export default {
  init(tabs) {
    if ($(tabs).length > 0) {
      $(tabs).each((i, form) => {
        $(form).on('change', (e) => {
          const values = $(e.target).serializeArray();
          
          values.map((vals) => {
            $(`[data-tab="${vals.name}-${vals.value}"]`).addClass('active').siblings('.active').removeClass('active');
          });
        });
      });
    }
  }
}