export default {
    init() {
        if(filteredBlog) {
            $(document).ready(function () {
                $('html, body').animate({
                    scrollTop: $('.blog_list').offset().top - 100
                }, 'slow');
            });
        }
    }
}